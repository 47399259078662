import React from 'react';
import {
  Button,
  IconButton,
  TextField as TextFieldBase,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import Modal from 'src/components/common/modal';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import LoaderButton from '../loaderButton';

const FIELDS = {
  COMMENTS: 'comments',
  REJECTIONCATEGORY: 'rejectionCategory'
};

const VALIDATION_SCHEMA = yup.object({
  [FIELDS.COMMENTS]: yup.string().required('Required'),
  [FIELDS.REJECTIONCATEGORY]: yup.string().required('Required')
});

function ActionModal({
  headerLabel,
  open,
  handleClose,
  onCancelPress,
  onSuccessPress,
  apiLoading,
  rejectionCategory = []
}) {
  const successBtnColor =
    headerLabel === 'Approve' ? 'rgba(76, 175, 80, 1)' : '#f44336';
  return (
    <Modal
      open={open}
      onClose={handleClose}
      withoutScroll
      childrenStyle={{ padding: 24 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography>{headerLabel}</Typography>
        <IconButton style={{ padding: 5 }} onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <Formik
        initialValues={{
          [FIELDS.COMMENTS]: '',
          [FIELDS.REJECTIONCATEGORY]: ''
        }}
        onSubmit={onSuccessPress}
        validationSchema={VALIDATION_SCHEMA}
      >
        {({ handleSubmit }) => (
          <>
            <Field
              name={FIELDS.REJECTIONCATEGORY}
              component={SelectField}
              options={rejectionCategory}
              label="Rejection Category"
            />
            <Divider
              style={{ width: '100%', marginBottom: 24, borderStyle: 'dashed' }}
            />
            <Field
              name={FIELDS.COMMENTS}
              component={TextField}
              label={headerLabel}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="outlined"
                style={{
                  marginRight: 10,
                  textTransform: 'capitalize'
                }}
                onClick={onCancelPress || handleClose}
              >
                Cancel
              </Button>
              <LoaderButton
                variant="contained"
                style={{
                  textTransform: 'capitalize',
                  backgroundColor: successBtnColor
                }}
                onClick={handleSubmit}
                loading={apiLoading}
              >
                {headerLabel} Reimbursement
              </LoaderButton>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default ActionModal;

function TextField({
  field: { onChange, value, name },
  form: { errors },
  label
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={`Reason For ${label}`}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        error={!!errors[name]}
      />
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}

function SelectField({
  field: { onChange, value, name },
  form: { errors },
  options,
  label
}) {
  return (
    <>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
        <Select name={name} value={value} onChange={onChange} label={label}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {(options || []).map((item) => {
            const label = item?.replace(/_/g, ' ');
            return (
              <MenuItem key={item} value={item}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText error>{errors[name]}</FormHelperText>
    </>
  );
}
