import React from 'react';
import { Paper } from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';

function RejectionBucketDetails({ data }) {
  return (
    <Paper style={{ padding: 16, marginTop: 15 }} variant="outlined">
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        <Typography
          color="rgba(79, 79, 79, 1)"
          style={{
            fontSize: 18,
            marginBottom: 15
          }}
        >
          Rejection Bucket Details
        </Typography>
        <Paper
          style={{
            textAlign: 'center',
            padding: 10,
            marginBottom: 20,
            borderColor: '#c1e3f1',
            backgroundColor: '#f2f5fa',
            position: 'relative'
          }}
          variant="outlined"
        >
          {data?.rejectionCategory && (
            <Typography
              color="rgba(79, 79, 79, 1)"
              variant="subtitle1"
              style={{
                textTransform: 'capitalize'
              }}
            >
              Rejection Category : {data?.rejectionCategory?.replace(/_/g, ' ')}
            </Typography>
          )}
          {data?.rectificationCategory && (
            <Typography
              color="rgba(79, 79, 79, 1)"
              variant="subtitle1"
              style={{
                textTransform: 'capitalize'
              }}
            >
              Rectification Category :{' '}
              {data?.rectificationCategory?.replace(/_/g, ' ')}
            </Typography>
          )}
        </Paper>
      </div>
    </Paper>
  );
}

export default RejectionBucketDetails;
