import axios from 'axios';
import { toast } from 'react-toastify';
import { addNativeNotification } from 'src/components/Notification';
import { GetApi } from 'src/services/middleware';
import { headers } from 'src/utils';
import { w3cwebsocket } from 'websocket';

// export const URL = process.env.REACT_APP_API_URL;
// export const PATIENT_URL = process.env.REACT_APP_API_PATIENT_URL;
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_API_URL;

export let URL = process.env.REACT_APP_URL;
export let PATIENT_URL = process.env.REACT_APP_PATIENT_URL;
export let SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export let RESELLER_URL = process.env.REACT_APP_RESELLER_URL;
export let ENROLLMENT_SERVICE_URL =
  process.env.REACT_APP_ENROLLMENT_SERVICE_URL;
export let CLAIM_SERVICE_URL = process.env.REACT_APP_CLAIM_SERVICE_URL;
export let socket = void 0;
export const startSocket = (id, history, getNotificationData) => {
  socket = new w3cwebsocket(`${SOCKET_URL}/notification/${id}/`); // socket Url
  // socket.send();
  if (socket) {
    socket.onopen = () => {
      console.log(':::WebSocket Connected at Client:::');
    };
    socket.onmessage = (message) => {
      try {
        let data = message.data;
        if (JSON.parse(message.data)) {
          data = JSON.parse(data);
        }
        if (data.read === true) {
          getNotificationData && getNotificationData();
          return;
        }
        let onClick = void 0;
        if (data.notification_type === 'prescription') {
          onClick = (event) => {
            window.focus();
            history.push({
              pathname: '/appointments/fill-prescription',
              state: {
                patient_id: data?.patient,
                appointment_id: data.appointment
              }
            });
          };
        } else if (data.notification_type === 'appointment') {
          onClick = () => {
            window.focus();
            history.push({
              pathname: '/patient/patient-profile',
              state: { patientId: data?.patient }
            });
          };
        }
        addNativeNotification({
          title: `aiqahealth : ${
            data.data && data.data.notification_type
              ? data.data.notification_type
              : data.notification_type
          }`,
          message: data.data && data.data.body ? data.data.body : data.body,
          duration: 10000,
          native: true,
          onClick
        });
      } catch (e) {
        console.log(':::errro is', e);
      }
    };
    socket.onclose = function () {
      console.log(':::::WebSocket Client Closed:::::');
      setSocket(null);
    };
  }
};

export const getSocket = () => {
  return socket;
};
export const setSocket = (status) => {
  socket = status;
};

// auth url
export const loginURL = `${PATIENT_URL}/accounts/api/doctor-login/`;
export const registerURL = `${PATIENT_URL}/accounts/api/doctor-register/`;
export const GenerateOtpApiURL = `${PATIENT_URL}/accounts/api/generate-otp/`;
export const verifyOtpURL = `${PATIENT_URL}/accounts/api/new-doctor-login/`;

// profile url
export const doctorProfileDetailURL = `${URL}/doctor/api/doctor-profile/`; //doctor profile basic details
export const bankDetailURL = `${URL}/doctor/api/doctor-detail/`; //doctor profile bank details
export const patientProfileDetailURL = (patient) =>
  `${URL}/doctor/api/patient-profile/?patient=${patient}`; //patient profile details

export const patientClaimURL = (id) =>
  `${RESELLER_URL}/patient-claim-info/${id}`; //patient profile details
export const patientPlanDetailURL = `${RESELLER_URL}/patient-plan`; //patient plan details
export const hospitalsURL = `${RESELLER_URL}/hospitals`; //get all hospitals
export const productBenefitURL = `${RESELLER_URL}/product`; //get product benefits

// appointment url
export const appointmentListURL = (date, query, page) =>
  `${URL}/doctor/api/doctor-appointments/?date=${date}&search=${query}&page=${page}`; //appointment list
export const createAppointmentURL = `${URL}/doctor/api/doctor-appointments/`; //create appointment
export const createPrescriptionAppointmentURL = `${URL}/doctor/api/prescription-appointment/`; //create appointment
export const fetchTodayAppointmentURL = (filter, from_date, to_date) =>
  `${URL}/doctor/api/todays-appointment/?filter=${filter}&from_date=${from_date}&to_date=${to_date}`; //fetch today appointments

export const todayAppointmentListURL = (
  page,
  filter,
  from_date,
  to_date,
  sort
) =>
  `${URL}/doctor/api/todays-appointment/?page=${page}&status=${filter}&from_date=${from_date}&to_date=${to_date}&ordering=${sort}`;
//disease, symptom, vital fetch url
export const fetchDiseaseURL = `${PATIENT_URL}/patient/api/disease-list/`; //fetch disease list
export const fetchSymptomListURL = (disease_id) =>
  `${URL}/doctor/api/symptom-list/?disease_id=${disease_id}`; //fetch symptom list
export const fetchSymptomOptionListURL = (symptom_id) =>
  `${URL}/doctor/api/symptom-option-list/?symptom_id=${symptom_id}`; //fetch symptom option list

// prescription url
export const publishPrescriptionURL = `${URL}/doctor/api/patient-prescription-publish/`; //publish prescription
export const previousPrescriptionURL = (patient, page) =>
  `${URL}/doctor/api/all-prescription/?patient=${patient}&page=${page}`;
export const downloardPrescriptionURL = (user_id, patient_id) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/prescription/${user_id}/${patient_id}`; // download prescription
export const updateSymptomVitalURL = `${URL}/doctor/api/patient-disease-option/`; //update consultation details
export const deletePrescriptionURL = `${URL}/doctor/api/prescription-delete/`; //update consultation details
export const weightManagementURL = `${URL}/doctor/api/weightmanagement/`; // prescription delete
export const medicalHistoryUpdateURL = `${URL}/patient/api/medical-history/`; //update patient medical history detail
export const medicalHistoryFetchURL = (patient) =>
  `${URL}/patient/api/medical-history/?patient=${patient}`; //fetch patient medical history

export const getPatientMemberURL = (user_id) =>
  `${URL}/doctor/api/admin-member/?user_id=${user_id}`; //fetch patient member

export const socialHistoryUpdateURL = `${URL}/patient/api/social-history/`; //update patient social history detail
export const socialHistoryFetchURL = (patient) =>
  `${URL}/patient/api/social-history/?patient=${patient}`; //fetch patient social history

export const familyHistoryUpdateURL = `${URL}/patient/api/family-history/`; //update patient family history detail
export const familyHistoryFetchURL = (patient) =>
  `${URL}/patient/api/family-history/?patient=${patient}`; //fetch patient family history

export const diagnosisUpdateURL = `${URL}/doctor/api/diagnosis/`; //update patient diagnosis detail
export const diagnosisFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/diagnosis/?patient=${patient}&appointment=${appointment}`; //update patient family history

export const medicationCreateURL = `${URL}/doctor/api/patient-medicine/`; // patient medication create
export const medicationDeleteURL = `${URL}/doctor/api/patient-medicine/`;
export const medicationFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/patient-medicine/?patient=${patient}&appointment=${appointment}`; //fetch patient medications

export const medicationGroupFetchURL = () =>
  `${URL}/doctor/api/medicine-group/?specialty=5`;

export const dietCreateURL = `${URL}/doctor/api/patient-diet/`; //update patient diet create
export const deleteCreateURL = `${URL}/doctor/api/patient-diet/`;
export const dietFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/patient-diet/?patient=${patient}&appointment=${appointment}`; //fetch patient diet

export const exerciseCreateURL = `${URL}/doctor/api/add-exercise/`; // patient exercise create
export const exerciseFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-exercise/?patient=${patient}&appointment=${appointment}`; //fetch patient exercise

export const woundCareCreateURL = `${URL}/doctor/api/add-wound-care/`; // patient wound care create
export const woundCareFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-wound-care/?patient=${patient}&appointment=${appointment}`; //fetch wound care

export const labTestCreateURL = `${URL}/doctor/api/add-lab-test/`; // patient lab test create
export const labTestFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-lab-test/?patient=${patient}&appointment=${appointment}`; //fetch lab tests

export const otherAdviceUpdateURL = `${URL}/doctor/api/detail-advice/`; //update patient advice
export const otherAdviceFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/detail-advice/?patient=${patient}&appointment=${appointment}`; //fetch other advice

// patient search api
export const patientFetchURL = (query) =>
  `${URL}/doctor/api/patient-search-admin-filterset/?${query}`;
// get consultation api
export const fetchPatientConsultationURL = (appointment, patient) =>
  `${URL}/doctor/api/patient-disease-option/?appointment=${appointment}&patient=${patient}`;

// get new consultation api
export const fetchPatientNewConsultationURL = (appointment, patient) =>
  `${URL}/doctor/api/chief-complain/?appointment=${appointment}&patient=${patient}`;

// prescription upload api
export const uploadPrescriptionURL = (appointment, patient) =>
  `${URL}/doctor/api/prescription/?appointment=${appointment}&patient=${patient}`;

// counsellor notes api
export const counsellorNotesURL = (patient, appointment) =>
  `${URL}/doctor/api/counselor-report/?appointment=${appointment}&patient=${patient}`;

// follow up api
export const appointmentFollowUpURL = (patient, appointment) =>
  `${URL}/doctor/api/appointment-followup/?appointment=${appointment}&patient=${patient}`;

// patient report list api
export const patientPreviousReportURL = (patient) =>
  `${URL}/doctor/api/report-list/?patient=${patient}`;

// patient report list api with pagination
export const patientPreviousReportPaginURL = (patient, page, report_type) =>
  `${URL}/doctor/api/get-report-list/?patient=${patient}&page=${page}${
    report_type ? `&report_type=${report_type}` : ''
  }`;

// fetch all symptom list
export const fetchAllSymptomURL = `${URL}/doctor/api/all-symptom-list/`;

// fetch all symptom list
export const patientTrackerURL = (patient, appointment) =>
  `${URL}/doctor/api/new-symptoms/?patient=${patient}&appointment=${appointment}`;

// fetch all patient Appointment list
export const patientAppointmentUrl = (patient_id, page, date) =>
  `${URL}/doctor/api/doctor-patient-appointments/?patient_id=${patient_id}&page=${page}${
    date ? `&date=${date}` : ''
  }`;

// fetch patient diet list
export const patientDietUrl = (patient_id, start, end) =>
  `${URL}/doctor/api/diet/?patient=${patient_id}&start=${start}${
    end ? `&end=${end}` : ''
  }`;

// fetch patient exercise list
export const patientExerciseUrl = (patient_id, start, end) =>
  `${URL}/doctor/api/exercise/?patient=${patient_id}&start=${start}${
    end ? `&end=${end}` : ''
  }`;

// fetch patient medication list
export const patientMedicationUrl = (patient_id, start, end) =>
  `${URL}/doctor/api/medicine/?patient=${patient_id}&start=${start}${
    end ? `&end=${end}` : ''
  }`;

// upload generated prescription report
export const uploadGeneratedPdfURL = () =>
  `${URL}/doctor/api/upload-prescription/`;

// initiate exotel call
export const exotelCall = (patient, appointment_id) =>
  `${URL}/doctor/api/make-call/?patient=${patient}&appointment=${appointment_id}`;

// create doctor available time slots
export const createTimeSlots = `${URL}/doctor/api/doctor-slots/`;

// create parameter track e.g vital, symptom
export const createParametersTrack = `${URL}/doctor/api/patient-parameters-track/`;

// fetch parameter track vitals an symptom
export const parametersTrackFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/patient-default-disease/?patient_id=${patient}&appointment_id=${appointment}`;

// fetch vital list
export const fetchVitalListURL = `${URL}/doctor/api/get-vitals/`;

// fetch appointment pending
export const fetchPendingAppointmentURL = (date, page) =>
  `${URL}/doctor/api/previous-appointments/?date=${date}&page=${page}`;

// fetch patient filled symptoms
export const filledSymptomsUrl = `${PATIENT_URL}/patient/api/patient-filled-symptoms/`;

// fetch patient filled vitals
export const filledVitalsUrl = `${PATIENT_URL}/patient/api/patient-filled-vitals/`;

// fetch patient filled symptoms by doctor
export const filledSymptomsDoctorUrl = `${PATIENT_URL}/patient/api/patient-filled-symptoms-doctor/`;

// fetch patient filled vitals by doctor
export const filledVitalsDoctorUrl = `${PATIENT_URL}/patient/api/patient-filled-vitals-doctor/`;
export const filledVitalsListDoctorUrl = `${PATIENT_URL}/patient/api/get-vitals/`;

// fetch doctor patient list
export const patientListUrl = `${URL}/doctor/api/patient-search-admin-filterset/`;
export const patientCount = `${URL}/doctor/api/patient-count/`;

// delete diagnosis image
export const deleteDiagnosisImageURL = `${URL}/doctor/api/diagnosis-delete/`;

// fetch patient diagnosis report
export const patientDiagnosisUrl = `${URL}/doctor/api/diagnosis-report/`;

// fetch appointment
export const fetchAppointmentDetail = (id) =>
  `${URL}/doctor/api/appointment-detail/?id=${id}`;

// appointment url
export const calendarAppointmentListURL = (date, query) =>
  `${URL}/doctor/api/doc-callender/?date=${date}&search=${query}`;

// fetch vitals List
export const vitalListUrl = `${URL}/doctor/api/get-vitals/`;

// fetch patient appointement count
export const patientAppointementCount = (patient) =>
  `${URL}/doctor/api/appointment-count/?patient=${patient}`;

// fetch doctor specilities
export const doctorSpecialityUrl = `${URL}/doctor/api/doctor-specialty/`;

// fetch doctor detail
export const doctorDetailUrl = `${URL}/doctor/api/doctor-home/`;

// generate prescription pdf
export const generatePrescriptionPdf = (patient_id, appoinment_id) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/prescription/${patient_id}/${appoinment_id}`;

export const uploadDocument = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/docs`;

// fetch patient adherence day wise
export const fetchPatientAdherence = (patient, start, end) =>
  `${URL}/doctor/api/day-wise-total-adherence/?patient=${patient}&start=${start}&end=${end}`;

// fetch patient adherence (e.g diet, exercise)
export const fetchPatientAdherenceURL = (patient, start, end) =>
  `${URL}/doctor/api/patient-adherence/?patient=${patient}&start=${start}&end=${end}`;

// fetch/ search medicine
export const medicineSearchUrl = (search, appointment_id = null) =>
  `${URL}/doctor/api/search-medicine/?medicine=${search}&appointment_id=${appointment_id}`;

export const notificaitonListUrl = `${URL}/doctor/api/notification-list/?user_type=doctor`;

// get insurd member details
export const getInsuredMemberDetails = () =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/getInsuredMemberByMobileNo`;
// update insured member details
export const updateInsuredMemberDetails = (mobile) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/updateInsuredMember`;
export const fetchNotificationList = async (date, query, page) => {
  try {
    const res = await GetApi(`${notificaitonListUrl}`, headers());
    if (res.status == 200) {
      return (
        res.data && {
          notifications: res.data.data,
          notification_count: res.data.count
        }
      );
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch state url
export const getStateUrl = `${CLAIM_SERVICE_URL}/claim-service/api/state`;
// fetch city url
export const getCityUrl = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/city/?stateId=${id}`;
// download lab report
export const getLabTestReportUrl = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/download_lab_report/${id}/download`;

// hospital details

// post hospital
export const postHospitalDetailsUrl = `${CLAIM_SERVICE_URL}/claim-service/api/hospital`;
// get hospital
export const getHospitalDetailsUrl = (name, cityId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/city/?name=${name}&cityId=${cityId}`;

// getapproveOverdueUrl

export const getapproveOverdueUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/approveOverDueClaim/${claimId}/${subClaimId}`;

// get disabled dates
export const getDisabledDates = `${ENROLLMENT_SERVICE_URL}/enrollment-service/NonServiceableDay`;

// search patient

export const getPatient = (query) =>
  `${URL}/doctor/api/patient-search/?search=${query}`;
// claim count

export const getClaimCountURL = `${CLAIM_SERVICE_URL}/claim-service/api/statusWiseClaim`;

export const getUserClaimURL = (id, key) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/consumeAndAvailableDays/${id}/${key}`;

export const getUserClaimableDaysURL = (id, key) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/daysForUserCanClaim/${id}/${key}`;

export const getUserClaimableAmountURL = (id, key) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/claimableAmountTotalClaimedAmount/${id}/${key}`;

export const getInProgressClaimURL = (id, key) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getInProgressClaim/${id}/${key}`;

export const getHraReportsDetailsURL = () =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/hra_reports`;

export const getHraReportURL = (hraid, action) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/hra_report_fetch/${hraid}/${action}`;

//initiate exotel call

export const exotelCallLogs = `${URL}/doctor/api/exotel-multipurpose-call/`;
export const exotelData = (page, query) =>
  `${URL}/doctor/api/exotel-list/?page=${page}${query}`;
export const exotelCallDetail = (call_sid) =>
  `${URL}/doctor/api/exotel-call-detail/?call_sid=${call_sid}`;

export const exotelSaveDisposition = `${URL}/doctor/api/exotel-list/`;

// Assign Diet Preference
export const getDietPreferenceOption = () =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/diet_preference/fetch_options`;

export const getDietPreferencePdfUrl = (preference, calories, cuisines) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/diet_preference/fetch_preview/${preference}/${calories}/${cuisines}`;

export const saveDietPreferenceUrl = () =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/diet_preference/saveDietPreviewDetails`;

export const getDietPreferenceData = (appoinment_id) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/diet_preference/getDietPreferenceDetails/${appoinment_id}`;

export const saveQueryRaiseUrl = () => `${URL}/doctor/api/appointment-query/`;
export const fetchQueryRaisedURL = (appointment) =>
  `${URL}/doctor/api/appointment-query/?appointment=${appointment}`;

export const fetchSearchByStatus = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/claimStatus`;
// remove doctor available time slots
export const removeTimeSlotsURL = `${URL}/doctor/api/doctor-remove-time-slot/`;

// get doc time slot
export const docTimeSlotURL = (id, date) =>
  `${URL}/doctor/api/appointment-time-api/?doctor_id=${id}&date=${date}`;

export const postEclinicStatusUpdateURL = `${URL}/doctor/api/eclinic-status-update/`;

//Reconcile url
export const postActionReconcileURL = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/approveReconcileClaim/${claimId}/${subClaimId}`;

// doctor logout api
export const logoutUrl = `${PATIENT_URL}/accounts/api/doctor-logout/`;

export const getPatnerLabReportURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/getLabTestDetailsByMobile`;

export const getOPResellers = `${CLAIM_SERVICE_URL}/claim-service/api/get_resellers_name`;
// fcm token
export const postFcmTokenURL = `${URL}/doctor/api/regenerate-fcm-token/`;

export const downloadClaimExcelURL = `${CLAIM_SERVICE_URL}/claim-service/api/downloadFOApprovedData`;
export const uploadClaimExcelURL = `${CLAIM_SERVICE_URL}/claim-service/api/uploadClaimData`;
export const viewJSONRecord = `${CLAIM_SERVICE_URL}/claim-service/api/foApprovedData`;

export const fetchAppointmentStatusURL = (appointment) =>
  `${URL}/doctor/api/check-appointment-status/?appointment_id=${appointment}`;

export const downloadPrescriptionMultipleLangURL = (
  user_id,
  patient_id,
  languageCode
) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/prescription/${user_id}/${patient_id}/?languageCode=${languageCode}`;

export const fetchInsuranceType = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/insurance_type`;
export const fetchResellerName = (value) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/reseller/getResellerName/${value}`;
export const fetchInsuranceDetails = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/insurancePolicyDetails`;
export const downloadCsvInsuranceReport = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/insurancePolicyExcelData`;

export const getSettlementHistoryURL = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/partial_settlement_history/${claimId}/${subClaimId}`;
export const postFoDocURL = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/approvedDoc?referenceType=CLAIM&claimId=${claimId}&subClaimId=${subClaimId}`;

export const getAllClaimCountURL = `${CLAIM_SERVICE_URL}/claim-service/api/statusWiseAllClaimCount`;

//comments//////////////
export const fetchTicketURL = `${CLAIM_SERVICE_URL}/claim-service/api/get-ticket`;
export const fetchCommentURL = `${CLAIM_SERVICE_URL}/claim-service/api/get-comment`;
export const addCommentURL = `${CLAIM_SERVICE_URL}/claim-service/api/add-comment`;
export const updateCommentViewURL = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/update-view-comment/${claimId}/${subClaimId}`;
export const commentDocUploadURL = (
  ClaimId,
  subClaimId,
  ticketId,
  commentId,
  createdBy
) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/upload-ticket-docs?referenceId=${ClaimId}&subReferenceId=${subClaimId}&ticketId=${ticketId}&commentId=${commentId}&referenceType=Claim&createdBY=${createdBy}`;

export const commentDocGetApi = (documentID) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/get-ticket-docs/${documentID}`;
//external claim
export const getExternalClaimUrl = `${CLAIM_SERVICE_URL}/claim-service/api/externalClaims`;
export const getExternalClaimStatusUrl = `${CLAIM_SERVICE_URL}/claim-service/api/getExternalClaimsCountStatusWise`;
export const raiseExternalClaimUrl = (id, subId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/raiseExternalClaim/${id}/sub-claimId/${subId}`;
export const getAllVendorUrl = `${CLAIM_SERVICE_URL}/claim-service/api/getAllVendor`;
export const documentVerificationUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/isDocumentVerificationDone/${claimId}/sub-claimId/${subClaimId}`;
export const rejectExternalClaimUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/rejectExternalClaim/${claimId}/sub-claimId/${subClaimId}`;
export const getExternalClaimDetailsUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getExternalClaimDetails/${claimId}/sub-claimId/${subClaimId}`;
export const externalClaimSettleUrl = `${CLAIM_SERVICE_URL}/claim-service/api/settleExternalClaim`;
export const getInsuranceTypeUrl = (type) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getInsuranceTypesOfVendor?vendorCode=${type}`;
export const getExternalClaimStatusHistoryUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getExternalStatusHistory/${claimId}/sub-claimId/${subClaimId}`;
export const approvedClaimUrl = (id, subId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/approveExternalClaim/${id}/sub-claimId/${subId}`;
export const queryExternalClaimUrl = (claimId, subClaimId) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/queryExternalClaim/${claimId}/sub-claimId/${subClaimId}`;

export const feedBackApiUrl = `${URL}/doctor/api/feedback-notification/`;
